<template>
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col">
        <button class="btn btn-primary" @click="printTable()"><i class="fa fa-print"></i> Print</button>
      </div>
    </div>
    <div class="row" id="exportable">
      <div class="col">
        <table class="table table-responsive betterText">
          <thead>
            <tr>
              <th>Name(s)</th>
              <th>Phone</th>
              <th>Arrival [Date | Day]</th>
              <th>Departure [Date | Day]</th>
              <th>Nights</th>
              <th>Room</th>
              <th>HQ Received Y/N</th>
              <th>Rx Req. Received Y/N</th>
              <th>Eating/Fasting</th>
              <th>Allergies</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="bk in bookings" v-key="bk.id">
            <tr>
              <td>
                <template v-for="client in bk.booking.clients" v-key="client.id">
                <span class="d-block">{{client.name}}</span>
                </template>
              </td>
              <td>
                <template v-for="client in bk.booking.clients" v-key="client.id">
                <span class="d-block">{{client.phone ? client.phone : client.mobile}}</span>
                </template>
              </td>
              <td>
                {{bk.arrival_date}} | {{ new Date(bk.arrival_date).toLocaleString('en-gb', {weekday:'short'}) }}
              </td>
              <td>
                {{bk.departure_date}} | {{ new Date(bk.departure_date).toLocaleString('en-gb', {weekday:'short'}) }}
              </td>
              <td>
                {{bk.booking.total_nights}}
              </td>
              <td>
                {{bk.room.name}}
              </td>
              <td>
                <span v-if="bk.booking.forms.length === 0">N</span>
                <span v-else>Y</span>
              </td>
              <td>
                
              </td>
              <td>

              </td>
              <td>
                <template v-if="bk.booking.forms.length > 0">
                  <template v-for="client in bk.booking.clients" v-key="client.id">
                  <span class="d-block">{{ hasAllergies(bk.booking.forms, client) }}</span>
                  </template>
                </template>
              </td>
            </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      bookings: [],
    };
  },
  methods: {
    fetch() {
      this.busy = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/bookings/due-ins/export")
        .then(({ data }) => {
          this.bookings = data;
        });
    },
    hasAllergies(forms, client) {
      var hasAllergy = "No allergies";
      var form = forms.find(f => f.client_id == client.id);
      if (form) {
        var res = JSON.parse(form.sections[3].client_response);
        if (res[14].userData) {
          if (res[14].userData[0]) {
            hasAllergy = res[14].userData[0];
            if (
              hasAllergy == "null" ||
              hasAllergy == "n/a" ||
              hasAllergy == "N/A"
            ) {
              hasAllergy = "No allergies";
            }
          }
        }
      }

      return hasAllergy;
    },
    printTable()
    {
      this.$nextTick(() => {
        const element = window.document.getElementById("exportable");

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }

        const win = window.open(
          "",
          "_blank",
          "toolbar=0,scrollbars=0,status=0",
          true
        );
        win.document.write(`
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            ${stylesHtml}
            <title>Due-ins Printout</title>
            <style type="text/css" media="print">
              @page { size: landscape; }
            </style>
          </head>
          <body>
            ${element.innerHTML}
          </body>
        </html>
      `);
        setTimeout(() => {
          win.document.close();
          win.focus();
          win.print();
          win.close();
          cb();
        }, 1000);
      });
    }
  },
  mounted() {
    this.fetch();
  },
  components: {
  }
};
</script>

<style>
.betterText {
  font-size: 11px !important;
}
</style>